// electron运行环境下不支持Promise.finally 引入core-js来支持Promise.finally写法
import 'core-js/features/promise/finally';
// import '@/core/Electron';
import Rsa from "@/utils/rsa";
import TreeSelect from "../components/TreeSelect"
import LabbolBasicComponents from 'labbol-basic-components';
// import LabbolBasicComponents from '../../../labbol-basic-components';
import views from '../views';
import { openPrint } from '../components/OpenPrint';
export default {
	install(Vue){
		Vue.prototype.Rsa = Rsa;
		Vue.component("TreeSelect", TreeSelect)
		Vue.use(LabbolBasicComponents);
		Vue.use(views);
	}
}

import {
	useForm, usePageWrapper, useTable, useDialog, PATTERNTYPESENUM, PATTERNSENUM, SvgComponentNames,
	LoginPath, LoginName, Login,
    RegisterPath, RegisterName, Register,
    ChangePasswordPath, ChangePasswordName, ChangePassword,
    ToolbarUser, ToolbarSubscribeApps,
	setUiConfig,
}
from 'labbol-basic-components';
// from '../../../labbol-basic-components';

export {
	useForm, usePageWrapper, useTable, useDialog, PATTERNTYPESENUM, PATTERNSENUM, SvgComponentNames,
	LoginPath, LoginName, Login,
    RegisterPath, RegisterName, Register,
    ChangePasswordPath, ChangePasswordName, ChangePassword,
    ToolbarUser, ToolbarSubscribeApps,
	openPrint,
}
