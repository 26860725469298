<template>
    <div class="PrintDesign__wrapper">
        <PageWrapper class="PrintDesign" v-bind="pageWrapperConfig" @save="handleSave" @preview="handlePreview"
            @import="handleOpenImportDialog">
            <template slot="statistics">
                <div class="print-toolbar">
                    <div class="pager-size-radio-group">
                        <el-radio-group v-model="currentPagerSize.value" @change="handlePagerSizeChange" size="small">
                            <template v-for="(item, index) in pagerSizes">
                                <el-radio-button v-if="item.value !== 'custom'" :key="index" :label="item.value">
                                    {{ item.label }}
                                </el-radio-button>
                            </template>
                        </el-radio-group>
                        <el-popover class="pager-size-custom" popper-class="pager-size-custom-popper" width="260"
                            placement="bottom" trigger="click">
                            <!-- title="设置纸张宽高(mm)" -->
                            <el-button slot="reference" :class="currentPagerSize.value === 'custom' ? 'is-active' : ''"
                                label="custom" size="small" @click="currentPagerSize.value = 'custom'">
                                自定义纸张
                            </el-button>
                            <BasicForm ref="FormRef" v-bind="pagerSizeCustomFormConfig"></BasicForm>
                            <el-button size="small" @click="handlePagerSizeConfirm">确定</el-button>
                        </el-popover>
                    </div>
                    <el-button size="small" @click="handleRotate">旋转纸张</el-button>
                    <el-button size="small" @click="handleClear">清空</el-button>
                    <div class="printer-select">
                        <div class="printer-select-label">打印机：</div>
                        <el-select v-model="currentPrinterIndex" placeholder="请选择默认打印机" size="small">
                            <el-option v-for="(item, index) in allPrinterList" :key="index" :label="item.name"
                                :value="index">
                                {{ item.name }} ({{ item.server.ip }})
                            </el-option>
                        </el-select>
                        <el-button size="small" :loading="printerListLoading" @click="handleRefreshPrinterList">
                            刷新
                        </el-button>
                    </div>
                </div>
                <div class="print-tip">
                    <div class="connect-state">
                        <div v-if="isConnected === true && !connectLoading" class="connected">
                            打印中转连接服务正常，请选择默认打印机进行打印
                        </div>
                        <div v-if="isConnected === false && !connectLoading" class="dis-connected">
                            打印中转服务连接超时，打印功能暂时无法使用，请<span @click="reconnect">重连</span>或联系客服
                        </div>
                    </div>
                    <!-- <el-tooltip class="item" effect="dark" content="" placement="left"> -->
                    <!-- <Notice class="notice" @click="handleNotice" /> -->
                    <i class="el-icon-download" @click="handleNotice"></i>
                    <!-- </el-tooltip> -->
                </div>

            </template>

            <DraggleCard ref="DraggleCardRef" />
            <HiPrintDesign />
            <HiPrintSetting />


        </PageWrapper>
        <!-- 打印预览 -->
        <PreviewDialog ref="PreviewDialogRef" />
        <!-- 导入打印json数据 -->
        <BasicDialog v-bind="getImportConfig" @confirm="handleImportSubmit" @close="handleImportClose"></BasicDialog>
        <!-- 注意事项 -->
        <NoticeDialog ref="NoticeDialogRef" />
    </div>
</template>

<script>
import { useForm, usePageWrapper, useDialog, } from "@/core/index";
import DraggleCard from "./components/DraggleCard";
import HiPrintDesign from "./components/Design";
import HiPrintSetting from "./components/Setting";
import PreviewDialog from './components/PreviewDialog';
import NoticeDialog from './components/NoticeDialog';
import deviceJson from "./config/deviceJson";
import iconMixin from './assets/icon.mixin';
import { hiprint, defaultElementTypeProvider, printerName, host, token, getAllDefaultPrinterList } from "../../modules/hiprint";
let hiprintTemplate;
export default {
    name: "PrintDesign",
    mixins: [iconMixin],
    components: {
        DraggleCard,
        HiPrintDesign,
        HiPrintSetting,
        PreviewDialog,
        NoticeDialog,
    },
    data() {
        return {
            importDialogVisible: false,
            /** hiprint.PrintTemplate构造的打印实例 */
            template: null,
            currentPagerSize: {},
            pagerSizes: [
                { label: '斑马ZT210(60mm*30mm)', value: '斑马ZT210', width: 60, height: 30 },
                { label: 'A4(210*297)', value: 'A4', width: 210, height: 297 },
                { label: '自定义纸张', value: 'custom' },
            ],
            detailFormData: {},
            printerList: [],
            currentPrinterIndex: '',

            printerListLoading: false,
            connectLoading: false,
            isConnected: null,

            /** 字段的下拉列表数据 */
            originPrintParamList: []
        }
    },
    mounted() {
        this.init();
    },
    beforeRouteEnter(to, from, next) {
        let id = to.query.id;
        let currentActiveMenu = to.meta.currentActiveMenu;
        if (!id) {
            next({
                path: currentActiveMenu || '/',
            });
        } else {
            next()
        }
    },
    computed: {
        id() {
            return this.$route.query.id ? decodeURIComponent(decodeURIComponent(this.$route.query.id)) : '';
        },
        pageWrapperConfig() {
            return usePageWrapper({
                pageHeaderConfig: {
                    leftBtns: [
                        {
                            text: "保存",
                            type: "Save",
                            emitEvent: "save",
                        },
                        // {
                        //     text: "导入",
                        //     emitEvent: "import",
                        // },
                        {
                            text: "打印预览",
                            svg: 'Printer',
                            emitEvent: "preview",
                        },
                    ],
                },
            })
        },
        pagerSizeCustomFormConfig() {
            return useForm({
                labelWidth: '50',
                labelPosition: 'left',
                model: { ...this.currentPagerSize },
                schemas: [
                    {
                        label: '宽度',
                        prop: 'width',
                        component: 'InputNumber',
                        defaultValue: this.currentPagerSize.width,
                        componentProps: {
                            style: {
                                width: '100%',
                            },
                            placeholder: '请输入纸张宽度',
                            size: 'small',
                            min: 1,
                            precision: 2,
                        },
                        colProps: {
                            span: 24,
                        },
                        rules: [
                            {
                                required: true,
                                message: '请输入纸张宽度',
                            }
                        ],
                    },
                    {
                        label: '高度',
                        prop: 'height',
                        component: 'InputNumber',
                        defaultValue: this.currentPagerSize.height,
                        componentProps: {
                            style: {
                                width: '100%',
                            },
                            placeholder: '请输入纸张高度',
                            size: 'small',
                            precision: 2
                        },
                        colProps: {
                            span: 24,
                        },
                        rules: [
                            {
                                required: true,
                                message: '请输入纸张高度',
                            }
                        ],
                    },
                ]
            });
        },
        getImportConfig() {
            return useDialog({
                title: '导入',
                visible: this.importDialogVisible,
                formConfig: {
                    hideRequiredAsterisk: true,
                    schemas: [
                        {
                            labelWidth: '0',
                            prop: 'json',
                            component: 'Input',
                            rules: [
                                { required: true, message: '请输入json' }
                            ],
                            defaultValue: deviceJson,
                            componentProps: {
                                placeholder: '请输入json',
                                type: 'textarea',
                                autosize: {
                                    minRows: 6
                                }
                            }
                        }
                    ]
                }
            });
        },
        /**
         * @returns { printer[] }
        */
        allPrinterList() {
            return this.printerList;
        },
        /**
         * @returns { printer }
        */
        currentPrinter() {
            return this.allPrinterList[this.currentPrinterIndex];
        },
        /**
         * @returns { { text: string; field: string; desc: string  }[] }
        */
        printParamList() {
            return this.originPrintParamList;
        }

    },
    watch: {
        currentPagerSize: {
            /**
             * @param {{ width: number, height: number }} val
            */
            handler(val) {
                console.log('currentPagerSize', val);
                hiprintTemplate && this.setPaper();
            },
            immediate: false,
        },
        allPrinterList: {
            /**
             * @param {printer[]} val 
            */
            handler(val) {
                let findIndex;
                if (!val.length) {
                    findIndex = '';
                }
                else if (this.currentPrinterIndex !== '') {
                    findIndex = val.findIndex(item => item.name === this.currentPrinter.name);
                    if (findIndex === -1) {
                        findIndex = val.findIndex(item => item.name === printerName);
                        if (findIndex === -1) {
                            findIndex = 0;
                        }
                    }
                }
                else if (this.currentPrinterIndex === '') {
                    findIndex = val.findIndex(item => item.name === printerName);
                    if (findIndex === -1) {
                        findIndex = 0;
                    }
                }
                console.log('watch.allPrinterList', val, findIndex);

                this.currentPrinterIndex = findIndex;
            },
            immediate: true
        },
        currentPrinterIndex: {
            handler(val) {
                console.log('currentPrinterIndex', val, this.currentPrinter);
            },
            immediate: true
        },
        currentPrinter: {
            handler(val) {
                console.log('currentPrinter', val);
            },
            immediate: true
        },
        printParamList: {
            handler(val) {
                console.log('printParamList', val);
            },
            immediate: false
        }
    },
    methods: {
        async init() {
            // let promise = Promise.all([
            //     this.getPrintDesign(),
            //     this.getPrintParamList(),
            // ]);
            let loading = this.$loading();
            try {
                await this.addPrintEventListeners();
                this.startConnect();
                await this.getPrintDesign();
                await this.getPrintParamList();
                await this.initPrint();
                await this.setPrintConfig();
                // hiprintTemplate.setFields(this.printParamList);
                // this.updateJson(this.detailFormData.printDesignData);
                console.log('init', hiprintTemplate);
            } catch (error) {
                console.log('init-error', error);

            }
            loading.close();
        },
        /**
         * @description 获取打印设计信息
        */
        getPrintDesign() {
            return new Promise((resolve, reject) => {
                this.api.getListAPIPrint(
                    {
                        printDesignId: this.id
                    },
                    'getPrintDesign'
                ).then(res => {
                    let data = res.data.data || { printDesignData: {} };
                    let { printDesignData } = data;
                    // this.detailFormData = data;
                    this.detailFormData =  { ...data, printDesignData: { panels: [ { paperNumberDisabled: true } ], ...printDesignData || {} },   };
                    console.log('getPrintDesign', this.detailFormData);
                    
                    resolve(printDesignData || {});
                }).catch(error => {
                    console.log('getPrintDesign.error', error);
                    reject(error);
                })
            })
        },
        /**
         * @description 根据类型获取打印参数列表
        */
        getPrintParamList() {
            return new Promise((resolve, reject) => {
                this.api.getListAPIPrint(
                    {
                        printDesignType: this.detailFormData.printDesignType,
                    },
                    'getPrintParamList'
                ).then(res => {
                    let data = res.data.data || {};
                    let texts = Object.keys(data).sort();
                    let paramList = texts.map(text => {
                        return {
                            text: text + '/' + data[text], desc: data[text], field: text
                        }
                    });
                    this.originPrintParamList = paramList;
                    resolve();
                    // hiprintTemplate.setFields(paramList);
                }).catch(error => {
                    console.log('error', error);
                    reject();
                })
            })
        },
        /**
         * @description 初始化打印页面
        */
        initPrint() {
            return new Promise((resolve, reject) => {
                try {
                    hiprint.init({
                        providers: [new defaultElementTypeProvider()],
                        // lang: this.$parent.lang,
                        // lang: "cn",
                    })
                    // 还原配置
                    // hiprint.setConfig();
                    // eslint-disable-next-line no-undef
                    hiprint.PrintElementTypeManager.buildByHtml($(".ep-draggable-item"));
                    $("#hiprint-design").empty();
                    console.log('initPrint', this.detailFormData.printDesignData);
                    
                    this.template = hiprintTemplate = new hiprint.PrintTemplate({
                        // template: panel,
                        template: this.detailFormData.printDesignData,
                        // // 图片选择功能
                        onImageChooseClick: target => {
                            // 测试 3秒后修改图片地址值
                            console.log('onImageChooseClick', target);
                            this.$refs.DraggleCardRef.openUpload(base64String => {
                                console.log('onImageChooseClick', base64String);
                                target.refresh(
                                    base64String,
                                    // {
                                    //     auto: true,
                                    //     width: true,
                                    //     real: true,
                                    // }
                                    false,
                                    (el, width, height) => {
                                        el.options.width = width;
                                        el.designTarget.css('width', width + "pt");
                                        el.designTarget.children('.resize-panel').trigger($.Event('click'));
                                    }
                                )
                            });
                            // setTimeout(() => {
                            //     target.refresh(url,options,callback)
                            //     callback(el, width, height) // 原元素,宽,高
                            //     target.refresh(url,false,(el,width,height)=>{
                            //       el.options.width = width;
                            //       el.designTarget.css('width', width + "pt");
                            //       el.designTarget.children('.resize-panel').trigger($.Event('click'));
                            //     })
                            //     // target.refresh(
                            //     //     "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAtAAAAIIAQMAAAB99EudAAAABlBMVEUmf8vG2O41LStnAAABD0lEQVR42u3XQQqCQBSAYcWFS4/QUTpaHa2jdISWLUJjjMpclJoPGvq+1WsYfiJCZ4oCAAAAAAAAAAAAAAAAAHin6pL9c6H/fOzHbRrP0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0u/SY9LS0tLS0tLS0tLS0n+edm+UlpaWlpaWlpaWlpaW/tl0Ndyzbno7/+tPTJdd1wal69dNa6abx+Lq6TSeYtK7BX/Diek0XULSZZrakPRtV0i6Hu/KIt30q4fM0pvBqvR9mvsQkZaW9gyJT+f5lsnzjR54xAk8mAUeJyMPwYFH98ALx5Jr0kRLLndT7b64UX9QR/0eAAAAAAAAAAAAAAAAAAD/4gpryzr/bja4QgAAAABJRU5ErkJggg==",
                            //     //     {
                            //     //         // auto: true, // 根据图片宽高自动等比(宽>高?width:height)
                            //     //         // width: true, // 按宽调整高
                            //     //         // height: true, // 按高调整宽
                            //     //         real: true, // 根据图片实际尺寸调整(转pt)
                            //     //     }
                            //     // )
                            // }, 3000)
                            // target.getValue()
                            // target.refresh(url)
                        },
                        // // 自定义可选字体
                        // // 或者使用 hiprintTemplate.setFontList([])
                        // // 或元素中 options.fontList: []
                        // fontList: [
                        //     { title: "微软雅黑", value: "Microsoft YaHei" },
                        //     { title: "黑体", value: "STHeitiSC-Light" },
                        //     { title: "思源黑体", value: "SourceHanSansCN-Normal" },
                        //     { title: "王羲之书法体", value: "王羲之书法体" },
                        //     { title: "宋体", value: "SimSun" },
                        //     { title: "华为楷体", value: "STKaiti" },
                        //     { title: "cursive", value: "cursive" },
                        // ],
                        // dataMode: 1, // 1:getJson 其他：getJsonTid 默认1
                        // history: true, // 是否需要 撤销重做功能
                        // willOutOfBounds: true, // 是否允许组件内的控件超出范围
                        // qtDesigner: true, // 是否开启类似QT Designer的唯一field生成模式
                        // onDataChanged: (type, json) => {
                        //     console.log(type) // 新增、移动、删除、修改(参数调整)、大小、旋转
                        //     console.log(json) // 返回 template
                        // },
                        // onUpdateError: e => {
                        //     console.log(e)
                        // },
                        fields: this.printParamList,
                        settingContainer: "#hiprint-setting",
                        // paginationContainer: ".hiprint-printPagination",
                    })
                    hiprintTemplate.design("#hiprint-design", { grid: true })
                    // 获取当前放大比例, 当zoom时传true 才会有
                    // this.scaleValue = hiprintTemplate.editingPanel.scale || 1;

                    resolve();
                } catch (error) {
                    console.log('initPrint.catch', error);
                    reject(error);
                }
            })
        },
        /**
         * @description 刷新打印机列表
        */
        handleRefreshPrinterList() {
            this.printerListLoading = true;
            hiprint.hiwebSocket.refreshPrinterList();
        },
        /**
         * @description 重连
        */
        reconnect() {
            this.connectLoading = true;
            hiprint.hiwebSocket.stop();
            this.startConnect();
        },
        /**
         * @description 开始连接
        */
        startConnect() {
            this.connectLoading = true;
            hiprint.hiwebSocket.setHost(
                host,
                token,
                isConnected => {
                    console.log('start', isConnected);
                    this.isConnected = isConnected;
                    this.connectLoading = false;
                }
            )
        },
        addPrintEventListeners() {
            return new Promise(resolve => {
                try {
                    hiprint.refreshPrinterList(list => {
                        if (this.printerListLoading){
                            this.$message({
                                type: 'success',
                                message: '刷新成功'
                            });
                        }
                        this.printerListLoading = false;
                        this.printerList = getAllDefaultPrinterList();
                    })
                    resolve();
                } catch (error) {
                    console.error('addPrintEventListeners.catch', error);
                    resolve();
                }
            })

        },
        removeHiprintEventListeners() {
            console.log('removeHiprintEventListeners', hiprint);
        },
        /**
         * @description 设置打印页面配置  根据设计面板宽高回显打印尺寸radio
        */
        setPrintConfig() {
            console.log('setPrintConfig');
            if (hiprintTemplate && hiprintTemplate.editingPanel) {
                let { width, height } = hiprintTemplate.editingPanel;
                if (typeof width === 'number' && typeof height === 'number') {
                    let findPage = this.pagerSizes.find(item => {
                        return item.width == width && item.height === height;
                    });
                    if (findPage) {
                        this.currentPagerSize = { ...findPage, width, height };
                    } else {
                        this.currentPagerSize = { ...this.pagerSizes.find(item => item.value === 'custom'), width, height };
                    }
                }
                else {
                    this.currentPagerSize = { ...this.pagerSizes.find(item => item.value === 'A4') };
                }
                console.log(`editingPanel.width=${width}, editingPanel.height=${height}`);

                // this.setPaper();

            }
            // hiprint.updateElementType('defaultModule.text', (type) => {
            //     // 不知道格式的情况，就 log 看看
            //     console.log('type', type);
            //     // type['fields'] = this.printParamList;
            //     // type.title = '这是更新后的元素';
            //     // type['field'] = this.printParamList[0].field;
            //     // console.log(type);
            //     return type;
            // })
        },
        handlePagerSizeChange(value) {
            // console.log('handlePagerSizeChange', value);
            let findPage = this.pagerSizes.find(item => item.value === value);
            if (!findPage) return;
            // if (!findPage || !findPage.value === this.currentPagerSize.value) return;
            this.currentPagerSize = { ...this.currentPagerSize, ...findPage };
            // console.log(this.pagerSizes);

        },
        /**
         * @description 设置纸张大小
        */
        setPaper() {
            try {
                let { width, height } = this.currentPagerSize;
                hiprintTemplate.setPaper(width, height)
            } catch (error) {
                this.$message.error(`操作失败: ${error}`)
            }
        },
        async handlePagerSizeConfirm() {
            let model = await new Promise((resolve) => {
                this.$refs.FormRef.validate((valid, model) => {
                    resolve(valid && model);
                });
            });
            console.log('handlePagerSizeConfirm', model);
            if (!model) return;
            this.currentPagerSize = {
                ...this.currentPagerSize,
                ...model
            }
        },
        /**
         * @description 旋转纸张
        */
        handleRotate() {
            if (hiprintTemplate) {
                hiprintTemplate.rotatePaper()
            }
        },
        /**
         * @description 清空
        */
        handleClear() {
            try {
                hiprintTemplate.clear();
            } catch (error) {
                this.$message.error(`操作失败: ${error}`);
            }
        },
        /**
         * @description 更新打印页数据
        */
        updateJson(objData) {
            if (hiprintTemplate) {
                try {
                    console.log('updateJson', objData);
                    hiprintTemplate.update(objData);
                    this.$nextTick(() => {
                        console.log(hiprintTemplate);
                        if (hiprintTemplate) { }
                    })


                } catch (e) {
                    this.$message.error(`更新失败: ${e}`)
                }
            }
        },
        /**
         * @description 获取打印页json数据
        */
        exportJson() {
            if (hiprintTemplate) {
                console.log(hiprintTemplate.getJson());
                return JSON.stringify(hiprintTemplate.getJson() || {})
            }
            return '';
        },
        handleOpenImportDialog() {
            // console.log('handleOpenImportDialog');
            this.importDialogVisible = true;
        },
        handleImportSubmit(model) {
            if (hiprintTemplate) {
                try {
                    this.updateJson(JSON.parse(model.json));
                    // hiprintTemplate.update(JSON.parse(model.json));
                } catch (error) {
                    console.log('handleImportSubmit.error', error);
                    return this.$message.error('导入失败，请检查导入内容是否正确');
                }
                this.handleImportClose();
            }
        },
        handleImportClose() {
            this.importDialogVisible = false;
        },
        handleSave() {
            let json = this.exportJson();
            console.log("save", JSON.parse(json))
            if (!json) {
                return;
            }
            let loading = this.$loading();
            this.api.postFormAPIPrint(
                {
                    printDesignId: this.id,
                    printDesignData: json,
                },
                'designPrintDesign',
                '',
                {
                    showSuccessMessage: true
                }
            ).then(() => {
                loading.close();
            }).catch(error => {
                console.log('error', error);
                loading.close();
            })
        },
        /**
         * @description 预览打印
        */
        handlePreview() {
            console.log('handlePreview', this.currentPrinter);

            this.$refs.PreviewDialogRef.open(hiprintTemplate, this.detailFormData, this.currentPrinter)
        },

        handleNotice() {
            this.$refs.NoticeDialogRef.open();
        }
    },
}
</script>

<style lang="less">
@import url('./index.less');
</style>
